/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../index.scss';
import {Header} from "../components/Header";
import {Dot} from "../components/Logo";
import {Plans} from "../components/Plans";
import {Page} from "../components/Page";
import {Faq} from "../components/Faq";


const PlansPage = () => {
  return <Page title='Choose Plan'>
    <Header/>
    <section>
      <h1>Choose Plan<Dot/></h1>
      <Plans />
      <Faq/>
    </section>
  </Page>;
};
export default PlansPage;
